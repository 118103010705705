
/* eslint-disable */
// @ts-nocheck

import stickerCover000 from './stickers/Cheeky Piggies/Cover/Crybaby.svg';
import stickerContent000 from './stickers/Cheeky Piggies/Content/Crybaby.svg';
import stickerCover001 from './stickers/Cheeky Piggies/Cover/Drool.svg';
import stickerContent001 from './stickers/Cheeky Piggies/Content/Drool.svg';
import stickerCover002 from './stickers/Cheeky Piggies/Cover/Fuming.svg';
import stickerContent002 from './stickers/Cheeky Piggies/Content/Fuming.svg';
import stickerCover003 from './stickers/Cheeky Piggies/Cover/Hi~.svg';
import stickerContent003 from './stickers/Cheeky Piggies/Content/Hi~.svg';
import stickerCover004 from './stickers/Cheeky Piggies/Cover/Holding Tears.svg';
import stickerContent004 from './stickers/Cheeky Piggies/Content/Holding Tears.svg';
import stickerCover005 from './stickers/Cheeky Piggies/Cover/Love Blows.svg';
import stickerContent005 from './stickers/Cheeky Piggies/Content/Love Blows.svg';
import stickerCover006 from './stickers/Cheeky Piggies/Cover/Me_ Really_.svg';
import stickerContent006 from './stickers/Cheeky Piggies/Content/Me_ Really_.svg';
import stickerCover007 from './stickers/Cheeky Piggies/Cover/OK.svg';
import stickerContent007 from './stickers/Cheeky Piggies/Content/OK.svg';
import stickerCover008 from './stickers/Cheeky Piggies/Cover/Sassy Flick.svg';
import stickerContent008 from './stickers/Cheeky Piggies/Content/Sassy Flick.svg';
import stickerCover009 from './stickers/Cheeky Piggies/Cover/Shockwave.svg';
import stickerContent009 from './stickers/Cheeky Piggies/Content/Shockwave.svg';
import stickerCover010 from './stickers/Cheeky Piggies/Cover/Snooze Drool.svg';
import stickerContent010 from './stickers/Cheeky Piggies/Content/Snooze Drool.svg';
import stickerCover011 from './stickers/Cheeky Piggies/Cover/Swag.svg';
import stickerContent011 from './stickers/Cheeky Piggies/Content/Swag.svg';
import stickerCover012 from './stickers/Cheeky Piggies/Cover/Sweatdrop.svg';
import stickerContent012 from './stickers/Cheeky Piggies/Content/Sweatdrop.svg';
import stickerCover013 from './stickers/Cheeky Piggies/Cover/Thumbs Up.svg';
import stickerContent013 from './stickers/Cheeky Piggies/Content/Thumbs Up.svg';
import stickerCover014 from './stickers/Cheeky Piggies/Cover/What_.svg';
import stickerContent014 from './stickers/Cheeky Piggies/Content/What_.svg';
import stickerCover015 from './stickers/Contorted Stickers/Cover/AFFiNE.svg';
import stickerContent015 from './stickers/Contorted Stickers/Content/AFFiNE.svg';
import stickerCover016 from './stickers/Contorted Stickers/Cover/AI.svg';
import stickerContent016 from './stickers/Contorted Stickers/Content/AI.svg';
import stickerCover017 from './stickers/Contorted Stickers/Cover/Cat.svg';
import stickerContent017 from './stickers/Contorted Stickers/Content/Cat.svg';
import stickerCover018 from './stickers/Contorted Stickers/Cover/Closed.svg';
import stickerContent018 from './stickers/Contorted Stickers/Content/Closed.svg';
import stickerCover019 from './stickers/Contorted Stickers/Cover/Eyes.svg';
import stickerContent019 from './stickers/Contorted Stickers/Content/Eyes.svg';
import stickerCover020 from './stickers/Contorted Stickers/Cover/Fire.svg';
import stickerContent020 from './stickers/Contorted Stickers/Content/Fire.svg';
import stickerCover021 from './stickers/Contorted Stickers/Cover/Info.svg';
import stickerContent021 from './stickers/Contorted Stickers/Content/Info.svg';
import stickerCover022 from './stickers/Contorted Stickers/Cover/King.svg';
import stickerContent022 from './stickers/Contorted Stickers/Content/King.svg';
import stickerCover023 from './stickers/Contorted Stickers/Cover/Love Face.svg';
import stickerContent023 from './stickers/Contorted Stickers/Content/Love Face.svg';
import stickerCover024 from './stickers/Contorted Stickers/Cover/Love.svg';
import stickerContent024 from './stickers/Contorted Stickers/Content/Love.svg';
import stickerCover025 from './stickers/Contorted Stickers/Cover/Notice.svg';
import stickerContent025 from './stickers/Contorted Stickers/Content/Notice.svg';
import stickerCover026 from './stickers/Contorted Stickers/Cover/Pin.svg';
import stickerContent026 from './stickers/Contorted Stickers/Content/Pin.svg';
import stickerCover027 from './stickers/Contorted Stickers/Cover/Question.svg';
import stickerContent027 from './stickers/Contorted Stickers/Content/Question.svg';
import stickerCover028 from './stickers/Contorted Stickers/Cover/Smile Face.svg';
import stickerContent028 from './stickers/Contorted Stickers/Content/Smile Face.svg';
import stickerCover029 from './stickers/Contorted Stickers/Cover/Stop.svg';
import stickerContent029 from './stickers/Contorted Stickers/Content/Stop.svg';
import stickerCover030 from './stickers/Paper/Cover/+1.svg';
import stickerContent030 from './stickers/Paper/Content/+1.svg';
import stickerCover031 from './stickers/Paper/Cover/A lot of question.svg';
import stickerContent031 from './stickers/Paper/Content/A lot of question.svg';
import stickerCover032 from './stickers/Paper/Cover/AFFiNE AI.svg';
import stickerContent032 from './stickers/Paper/Content/AFFiNE AI.svg';
import stickerCover033 from './stickers/Paper/Cover/Arrow.svg';
import stickerContent033 from './stickers/Paper/Content/Arrow.svg';
import stickerCover034 from './stickers/Paper/Cover/Atention.svg';
import stickerContent034 from './stickers/Paper/Content/Atention.svg';
import stickerCover035 from './stickers/Paper/Cover/Blue Screen.svg';
import stickerContent035 from './stickers/Paper/Content/Blue Screen.svg';
import stickerCover036 from './stickers/Paper/Cover/Boom.svg';
import stickerContent036 from './stickers/Paper/Content/Boom.svg';
import stickerCover037 from './stickers/Paper/Cover/Cool.svg';
import stickerContent037 from './stickers/Paper/Content/Cool.svg';
import stickerCover038 from './stickers/Paper/Cover/Dino.svg';
import stickerContent038 from './stickers/Paper/Content/Dino.svg';
import stickerCover039 from './stickers/Paper/Cover/Histogram.svg';
import stickerContent039 from './stickers/Paper/Content/Histogram.svg';
import stickerCover040 from './stickers/Paper/Cover/Local First.svg';
import stickerContent040 from './stickers/Paper/Content/Local First.svg';
import stickerCover041 from './stickers/Paper/Cover/Medal.svg';
import stickerContent041 from './stickers/Paper/Content/Medal.svg';
import stickerCover042 from './stickers/Paper/Cover/Notice.svg';
import stickerContent042 from './stickers/Paper/Content/Notice.svg';
import stickerCover043 from './stickers/Paper/Cover/Pin.svg';
import stickerContent043 from './stickers/Paper/Content/Pin.svg';
import stickerCover044 from './stickers/Paper/Cover/Star.svg';
import stickerContent044 from './stickers/Paper/Content/Star.svg';

function buildStickerTemplate(data) {
  return {
    name: data.name,
    preview: data.cover,
    type: 'sticker',
    assets: {
      [data.hash]: data.content,
    },
    content: {
      type: 'page',
      meta: {
        id: 'doc:home',
        title: 'Sticker',
        createDate: 1701765881935,
        tags: [],
      },
      blocks: {
        type: 'block',
        id: 'block:1VxnfD_8xb',
        flavour: 'affine:page',
        props: {
          title: {
            '$blocksuite:internal:text$': true,
            delta: [
              {
                insert: 'Sticker',
              },
            ],
          },
        },
        children: [
          {
            type: 'block',
            id: 'block:pcmYJQ63hX',
            flavour: 'affine:surface',
            props: {
              elements: {},
            },
            children: [
              {
                type: 'block',
                id: 'block:N24al1Qgl7',
                flavour: 'affine:image',
                props: {
                  caption: '',
                  sourceId: data.hash,
                  width: 0,
                  height: 0,
                  index: 'b0D',
                  xywh: '[0,0,460,430]',
                  rotate: 0,
                },
                children: [],
              },
            ],
          },
        ],
      },
    },
  };
}

function lcs(text1: string, text2: string) {
  const dp: number[][] = Array.from({ length: text1.length + 1 })
    .fill(null)
    .map(() => Array.from<number>({length: text2.length + 1}).fill(0));

  for (let i = 1; i <= text1.length; i++) {
    for (let j = 1; j <= text2.length; j++) {
      if (text1[i - 1] === text2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  return dp[text1.length][text2.length];
}

const templates = {
  "Cheeky Piggies": [     buildStickerTemplate({
        name: "Crybaby",
        cover: stickerCover000,
        content: stickerContent000,
        hash: "bRWBcaZveq6swjn8MwKVISsVnAr2tf4ZHTSTU-eRA5Q=",
      }),
     buildStickerTemplate({
        name: "Drool",
        cover: stickerCover001,
        content: stickerContent001,
        hash: "BUwkYl7SHNQCypB_SvkggKwAD3XxCRUPV6dorpW_ki0=",
      }),
     buildStickerTemplate({
        name: "Fuming",
        cover: stickerCover002,
        content: stickerContent002,
        hash: "Iu2DZ5PecYn6Rg7ONIzLqIVZa2v5WYnRKkMv8qTD8a8=",
      }),
     buildStickerTemplate({
        name: "Hi~",
        cover: stickerCover003,
        content: stickerContent003,
        hash: "h6r0wW-eIhWUF1AkN_EnHv-q8VfpZ4NOQKKTsbU8RPc=",
      }),
     buildStickerTemplate({
        name: "Holding Tears",
        cover: stickerCover004,
        content: stickerContent004,
        hash: "NnXjSqFfmw_D3Ne13JOx0yXIWtA9Exm6hggPGDgDfgc=",
      }),
     buildStickerTemplate({
        name: "Love Blows",
        cover: stickerCover005,
        content: stickerContent005,
        hash: "Oggqz68tzBBYevbwcwXqZjb4im48-f3hh94wf8RS-Ok=",
      }),
     buildStickerTemplate({
        name: "Me_ Really_",
        cover: stickerCover006,
        content: stickerContent006,
        hash: "W8dfy2MD-Fu2VOIPcYfHOuPNBnEIWcFg8TJJeta9iOc=",
      }),
     buildStickerTemplate({
        name: "OK",
        cover: stickerCover007,
        content: stickerContent007,
        hash: "aTpuWm7bxzUevhFn_xyIz0HO5YD-I4GmdoPvmw590PY=",
      }),
     buildStickerTemplate({
        name: "Sassy Flick",
        cover: stickerCover008,
        content: stickerContent008,
        hash: "ai5PdJq184Vxlagtbo5fo90RIvT7K0kQtKlhFF0T3h0=",
      }),
     buildStickerTemplate({
        name: "Shockwave",
        cover: stickerCover009,
        content: stickerContent009,
        hash: "NfiIZ-FHd2XWYF8L7pp8X1M3nGTM3-005VUtCOchld8=",
      }),
     buildStickerTemplate({
        name: "Snooze Drool",
        cover: stickerCover010,
        content: stickerContent010,
        hash: "HiRDmqZNvnKQDBX05caQF4Fg9PHh4_ZS0n_alWZcQ_M=",
      }),
     buildStickerTemplate({
        name: "Swag",
        cover: stickerCover011,
        content: stickerContent011,
        hash: "4bEGq5-p-s6HfbtbVNwGEvEg-YEQ8wA8NA7Uj_vxTBE=",
      }),
     buildStickerTemplate({
        name: "Sweatdrop",
        cover: stickerCover012,
        content: stickerContent012,
        hash: "6axmrPIHx4ahOGB_TtjLOPh4J6HYggLxxx0VGxnMu2E=",
      }),
     buildStickerTemplate({
        name: "Thumbs Up",
        cover: stickerCover013,
        content: stickerContent013,
        hash: "r97GwoejPTxjumyvS9kdAnB16nZvlM81xsHo0FqdUrM=",
      }),
     buildStickerTemplate({
        name: "What_",
        cover: stickerCover014,
        content: stickerContent014,
        hash: "JqWfcP9Q0kGE4wDuVZCi4lW2U7O15trpL--fdNrRJvQ=",
      }),],
"Contorted Stickers": [     buildStickerTemplate({
        name: "AFFiNE",
        cover: stickerCover015,
        content: stickerContent015,
        hash: "i3piAMnoD4STQnEjTrAe_ZRdwHcD34n-sJZY8IN1blg=",
      }),
     buildStickerTemplate({
        name: "AI",
        cover: stickerCover016,
        content: stickerContent016,
        hash: "VZJPB8ZBVtiZ-m04KNtlguY_t9VLx4itHILIQ3l1MRw=",
      }),
     buildStickerTemplate({
        name: "Cat",
        cover: stickerCover017,
        content: stickerContent017,
        hash: "IS6xbnAo5WXDRxnP98UBkdOP2Zt2luQXEojcLfnfsR4=",
      }),
     buildStickerTemplate({
        name: "Closed",
        cover: stickerCover018,
        content: stickerContent018,
        hash: "wzrJyCiyflFnyvvHdH2XONsuwbuw119wiFCekvopsmQ=",
      }),
     buildStickerTemplate({
        name: "Eyes",
        cover: stickerCover019,
        content: stickerContent019,
        hash: "eT4Nbl90OC9ivTjRBmEabaWqjdmITjCgOtTJNSJu1SU=",
      }),
     buildStickerTemplate({
        name: "Fire",
        cover: stickerCover020,
        content: stickerContent020,
        hash: "cQnt7T9qxI5-It-reeo3E4XVA3HA89L2myi1k2EJfn8=",
      }),
     buildStickerTemplate({
        name: "Info",
        cover: stickerCover021,
        content: stickerContent021,
        hash: "kwKlgzVYNRk4AyOJs3Xtyt0vMWovo-7BfEqaWndDInM=",
      }),
     buildStickerTemplate({
        name: "King",
        cover: stickerCover022,
        content: stickerContent022,
        hash: "W-RCNTaadPNEI9OALAGHqv1cGmYD1y7KxIRGLsbr-DM=",
      }),
     buildStickerTemplate({
        name: "Love Face",
        cover: stickerCover023,
        content: stickerContent023,
        hash: "51B1S9eZ1rgxT-zG5npI_5l1sGss6dTVYiyut5fNPrs=",
      }),
     buildStickerTemplate({
        name: "Love",
        cover: stickerCover024,
        content: stickerContent024,
        hash: "fK5Bk7hxwSEHuNQ2WfO-ysII_T20z37P1JvLf00ocUQ=",
      }),
     buildStickerTemplate({
        name: "Notice",
        cover: stickerCover025,
        content: stickerContent025,
        hash: "RS787c3FcijjBEhKrKFa6KwB8ZINUD5MSCEMWL7F53w=",
      }),
     buildStickerTemplate({
        name: "Pin",
        cover: stickerCover026,
        content: stickerContent026,
        hash: "HDozRCXEtlDfNFFs3sSozkvXUVAP3XXd3zQVI8aW1ak=",
      }),
     buildStickerTemplate({
        name: "Question",
        cover: stickerCover027,
        content: stickerContent027,
        hash: "bvNeY3Q-At8NxFzcjTYx_mn3YnJkbUhh6XEBp3xB0Uk=",
      }),
     buildStickerTemplate({
        name: "Smile Face",
        cover: stickerCover028,
        content: stickerContent028,
        hash: "nBVc87wjO0NnM4utzjOLxjUzFjeFcf90C0jkgrpBhrA=",
      }),
     buildStickerTemplate({
        name: "Stop",
        cover: stickerCover029,
        content: stickerContent029,
        hash: "oH6E3y8ZpdgrMGbtcSX5k3NASEkgayohDCEoO0eU7hE=",
      }),],
"Paper": [     buildStickerTemplate({
        name: "+1",
        cover: stickerCover030,
        content: stickerContent030,
        hash: "FEF1FPZ9H1lIO54e6gP5RlNNZqukz3ADuzPFgog5qH4=",
      }),
     buildStickerTemplate({
        name: "A lot of question",
        cover: stickerCover031,
        content: stickerContent031,
        hash: "yKPa7vqOxC6rh-e0SVdlp0RwMWQ9mzDKTtE5g2UnHGk=",
      }),
     buildStickerTemplate({
        name: "AFFiNE AI",
        cover: stickerCover032,
        content: stickerContent032,
        hash: "FwBs2WApEGkiFmu1XR4fHZ_7fOlSsSBdYEyGs2lDeLk=",
      }),
     buildStickerTemplate({
        name: "Arrow",
        cover: stickerCover033,
        content: stickerContent033,
        hash: "evuSkommPr7PBAWCioYDRQpKPZGoY6izIGev2C8Xdt0=",
      }),
     buildStickerTemplate({
        name: "Atention",
        cover: stickerCover034,
        content: stickerContent034,
        hash: "Lmvftjmkw5oQEyZ2VP6eTohbXgQyEtNWKkrg9AbDknI=",
      }),
     buildStickerTemplate({
        name: "Blue Screen",
        cover: stickerCover035,
        content: stickerContent035,
        hash: "30OHymd5x-3zr_5KxQm3DzVfxyWWAf0QnmfHpIOoLzQ=",
      }),
     buildStickerTemplate({
        name: "Boom",
        cover: stickerCover036,
        content: stickerContent036,
        hash: "mpTlbN8fJWJUMlHcCCrQuzKTK844-F9YuebgR0kgJa8=",
      }),
     buildStickerTemplate({
        name: "Cool",
        cover: stickerCover037,
        content: stickerContent037,
        hash: "3OujPx_YOY1MTqmgrbWaNDJlJeoLNvTWw96gW22rxps=",
      }),
     buildStickerTemplate({
        name: "Dino",
        cover: stickerCover038,
        content: stickerContent038,
        hash: "j13ZqHGUnVdGW3_1uWw_sFYeHj1SFoNsi5JwrTvpC-k=",
      }),
     buildStickerTemplate({
        name: "Histogram",
        cover: stickerCover039,
        content: stickerContent039,
        hash: "A1oGPUmv-Ypb-W7_jPgpSsVGA71J8njyr9f-97UnJQg=",
      }),
     buildStickerTemplate({
        name: "Local First",
        cover: stickerCover040,
        content: stickerContent040,
        hash: "LFIRZK4TswzJvThRO2Vch_aqfY2UZ6kjAyAEsQS-hHM=",
      }),
     buildStickerTemplate({
        name: "Medal",
        cover: stickerCover041,
        content: stickerContent041,
        hash: "cMIe6PYQLi0s9ryW3fbiXA9ACs3YsQFDtKjlfliXTC8=",
      }),
     buildStickerTemplate({
        name: "Notice",
        cover: stickerCover042,
        content: stickerContent042,
        hash: "oafBAmM8MB094GI9I4U2iG6TWoZpCoa4nDmGY2eH_Kw=",
      }),
     buildStickerTemplate({
        name: "Pin",
        cover: stickerCover043,
        content: stickerContent043,
        hash: "kEy0pTA3dsClFtIwaJJV9NZQvn2xib-biyFJvRp9tzM=",
      }),
     buildStickerTemplate({
        name: "Star",
        cover: stickerCover044,
        content: stickerContent044,
        hash: "Au-JbxT7XZvvDueaVg37tJ4kyH9vLxiC7tLgcbrZV48=",
      }),],
}

export const builtInTemplates = {
  list: async (category: string) => {
    return templates[category] ?? []
  },

  categories: async () => {
    return Object.keys(templates)
  },

  search: async(query: string) => {
    const candidates: unknown[] = [];
    const cates = Object.keys(templates);

    query = query.toLowerCase();

    for(const cate of cates) {
      const templatesOfCate = templates[cate];

      for(const temp of templatesOfCate) {
        if(lcs(query, temp.name.toLowerCase()) === query.length) {
          candidates.push(temp);
        }
      }
    }

    return candidates;
  },
}
