import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51Uy27bMBC8+yuIAAWSAw1ZcdxWQU8F+gs9FEVBiSuLDcUlSOrhFP33givJlmU3qHuSNMt9zM5Q6x+b5qXf1f0j+7VizKJXQaHJWKl6kM8rxpTxEDKWxPdcFC97h42RvECNLmOtcPeci7JUBvgsXKMUejj0EDNfuTIS+kXCiA6nH55Xv1fraZ7tv8wjlbdaHDJWaugjILTaG64C1D5jBZgALsI/Gx9UeeAFmgAmzEM3D/ZEg3E+fScZez4DNksgHYBOyVBNfY7ZtJ0K1L4Ky9iGYrUy/Ho8pXiOPffqVZl9xnJ0EhzPkZZRogkxBAtyhOfCw8PxVDc22Ca0Vx2PTU03613ErJCSmqSJ7Vm6tdTjJJADLYJq4TafYAtOiwO3wsDcL8SqEhK7Ra5FG3PG4HiWWDshVeMztkmH0WrRHzkUQhf3myRpK8bZY2p7SsQmRKYZM2jgTOTdwn0i96ibQOQC2oylYxM31I87+S83vadGbypV8d2lULurQm2fBuJurwzPMQSsp33Men6gnh06yXMH4iVj9OARIaNWKgD3VhSQMeuAd05Y2lcLrtTYEXCeNSv/kcpfXM2LOxgDHIx8496OnuO08m0y6Ur0CBNNwIjt4zyDCPNJ1rTxL4gB3FcVqs+V0tKBGdSdF7+S66AF52EQKM4qlYNiNDt2fIz/nZsPwoWzooKKzQmczHqm2eSno7RJ8u5MgcOJ+/FiJmy7IJFfl2LJpkDd1CZmfpMiiMGnn+5KoT3cfb/4JasoD4cWTPCnu3MtlS3+msvcgcJs4OK2gUfhR4f/Ae/X1ADMBgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mx6';
export var confirmModalContainer = '_1ukx6mxb';
export var confirmModalContent = '_1ukx6mxa';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mx5';
export var modalContentWrapper = '_1ukx6mx4';
export var modalDescription = '_1ukx6mx8';
export var modalFooter = '_1ukx6mx9';
export var modalHeader = '_1ukx6mx7';
export var modalOverlay = '_1ukx6mx3';
export var promptModalContent = '_1ukx6mxc';
export var widthVar = 'var(--_1ukx6mx0)';