import "../core/src/modules/peek-view/view/modal-container.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/peek-view/view/modal-container.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU227bMAx9z1fwZUACRIHcpG3qYED/YxgGxaIdLrLkyXIuHfLvg2Q7tXMv9mDAFMnDc+hjva9xn1qRYwm/ovyPxtJO4e8AILUmDy8AzgpdpsbmMZSJUDjkk4iPFgOAwwDAmWtlUVNzGLyfT5mFrmOzKURCbh8DXzwws0Pu2BhdabzJ4vlUa5/H4SLF6Abgy1XA6DogbwHjeEO4ZUEDOTKaZdZUxbBFfxuFVqEpFyEtKxteYtgIO2SsLfSrugSncXsdTIsc48/VXEYwSj6K8HIZoS/pdTS+WzP/iuxuXUpKsdxIjCE1diusLPsFjnLSGUsrndR4SbWkhC3xg9AO+WT2NAY+Bj55no8hemCpr7dXMvXjjxZlxlJGZyquzemu/s6c2f/M6eo5W/2XTXIHIZhk0kYi1BamxokhpR1Kr4V0ia75Uz4YaYm7Vo5IU9LImlP/uYUKPliKZO2NpCVLjDL2pGGpRLJm09ozZzvokXzrkVw+QlJSWSixjyFVuAumU5RpRg7zMoYEtUPrj39XpaN0zxKjHWrXTT2ms0MsCcRuT/aHrHTCOp/aknSrGCLOv/lwhZStXBt3kGVAvr2k114HPtAx73WkoaNh9NYn1IS52LGW8tNsXux6AFl99yq/suhczn1DfKbNBq0Se1YIjaouPlpKWlP4m8WhjSEE5UpIsx3yYgf+eSp2YLOlGPL68vD3B5+PagBjJVpmhaSqjCFIAMhJs5bslPP60FROkcYYtNG46DnOohKONtiX/0MKJ5g2jLyDRLjRvjtb4c/GsOGc4Qa1K1vYDsDquD9Wrizp9en/xqKWbPMRZg3/QkhJOgt6gAOH6KVJnMwUlTOLweEfuEjO43YIAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var animationTimeout = 'var(--_1mqnesr0)';
export var animationType = 'var(--_1mqnesr2)';
export var containerPadding = '_1mqnesrf';
export var modalContent = '_1mqnesrg';
export var modalContentContainer = '_1mqnesrc';
export var modalContentContainerWithFade = '_1mqnesre';
export var modalContentContainerWithZoom = '_1mqnesrd';
export var modalContentWrapper = '_1mqnesrb';
export var modalControls = '_1mqnesrh';
export var modalOverlay = '_1mqnesra';
export var transformOrigin = 'var(--_1mqnesr1)';